import { Input as DSInput, InputProps as DSInputProps } from 'src/design-system'
import cn from 'classnames'
import React from 'react'
import { InputWrapper } from 'components/atoms/input'
import styles from './styles.module.scss'

export type InputProps = {
  className?: string
  inputClassName?: string
  forceShowPlaceholder?: boolean
  invalid?: boolean
  naked?: boolean
  nonFocusedPlaceholder?: React.ReactNode
  onBlur?: () => void
  onChange?: (value: string | null) => void
  value?: string | null
} & Omit<DSInputProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>

const filled = (value?: string | null) => {
  if (value === undefined || value === null) return false
  if (typeof value === 'string' && value === '') return false

  return true
}

const sanitizedValue = (value: string) => {
  return filled(value) ? value : null
}

export const Input: React.VFC<InputProps> = (props) => {
  const {
    className,
    inputClassName,
    forceShowPlaceholder,
    invalid,
    naked,
    nonFocusedPlaceholder,
    onBlur,
    onChange,
    value,
    ...restProps
  } = props

  return (
    <InputWrapper className={className}>
      <DSInput
        className={cn(
          'px-4 py-3',
          styles.input,
          inputClassName,
          !forceShowPlaceholder && filled(value) && styles.filled,
          naked && 'border-transparent'
        )}
        isInvalid={invalid}
        naked={naked}
        onBlur={() => onBlur?.()}
        onChange={(e) => onChange?.(sanitizedValue(e.target.value))}
        value={value || undefined}
        {...restProps}
      />
      <div className={styles.nonFocusedPlaceholder}>
        {nonFocusedPlaceholder}
      </div>
    </InputWrapper>
  )
}
