import cn from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

type InputWrapperProps = {
  children: React.ReactNode
  className?: string
}

export const InputWrapper: React.VFC<InputWrapperProps> = (props) => {
  const { children, className } = props

  return <div className={cn(className, styles.wrapper)}>{children}</div>
}
